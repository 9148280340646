import moment from 'moment';

jQuery( document ).ready(function($) {

  // mobile menu
  $('.menu-trigger, .menu-item').click(function(){
      $('nav').toggleClass('active')
  })

  // Tour
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Umi/events?app_id=45PRESS_umi',
    method: 'GET',
    dataType: 'json',
    xhrFields: {
        withCredentials: false
    },
    error: () => {
    },
    success: data => {
        const events = $( '#events' );
        let html = '';
        if ( data.length ) {
            for ( let event of data ) {
                const event_location = typeof event.region !== 'undefined' ? event.venue.city + ', ' + event.venue.region : event.venue.city + ', ' + event.venue.country;
                html += '<div class="event" data-bit-id="' + event.id + '">';
                html += '<div class="event-date">' + moment( event.datetime ).format( 'D MMM' ) + '</div>';
                html += '<div class="event-info">';
                html += '<div class="event-venue">' + event.venue.name + '</div>';
                html += '<div class="event-location">' + event_location + '</div>';
                html += '<a href="' + event.url + '" target="_blank" class="btn btn-white">Tickets</a>';
                html += '</div>';
                html += '</div>';
            }
            events.html( html );
        } else {
            events.html( 'No upcoming events.' );
        }
    }
  });

//   FORM
  $("#umi-nl-form").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
        type: "POST",
        url: "https://subs.sonymusicfans.com/submit",
        dataType: "json",
        data: data,
        xhrFields: {
            withCredentials: false
        },
        success: function (data) {
            $("#umi-nl-form").hide()
            $(".message-error").hide()
            $(".message-success").show()
        },
        error: function (err) {
            $(".message-error").show()
        }
    });
  });

    //popup
    $('.music-trigger').click(function(e){
        e.preventDefault()
        $('.popup-music').toggleClass('active')
    })
    $('.videos-trigger').click(function(e){
        e.preventDefault()
        $('.popup-videos').toggleClass('active')
    })
    $('.popup-close').click(function(){
        $(this).parent().parent().parent().toggleClass('active')
    })

    // sliders
    $('.music-slider, .videos-slider').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>'
      });
});